import React from 'react'

export const FlowIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="33"
      height="32"
      viewBox="0 0 800.13 638.71"
      fill="none"
      {...props}
    >
      <defs>
        <style>
          {`
            .cls-1{fill:#fff;}
            .cls-1, .cls-2{stroke:${props.fill ? props.fill : '#000'};stroke-miterlimit:10;stroke-width:38.29px;}
            .cls-2{fill:none;opacity:1;}
          `}
        </style>
      </defs>
      <g>
        <rect className="cls-2" x="438.45" y="57.34" width="342.53" height="524.03" rx="46.11" ry="46.11"/>
        <rect className="cls-2" x="19.15" y="57.34" width="342.53" height="524.03" rx="46.11" ry="46.11"/>
        <rect className="cls-1" x="203.84" y="19.15" width="392.46" height="600.42" rx="52.83" ry="52.83"/>
      </g>
    </svg>
  );
}
