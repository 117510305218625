function truncateString(str, limit) {
  if (!str || !limit) {
    return "";
  }

  if (typeof str !== "string" || typeof limit !== "number") {
    throw new Error(
      "Invalid input: str must be a string and limit must be a number."
    );
  }

  if (str.length > limit + 3) {
    return str.slice(0, limit) + "...";
  }

  return str;
}

function daysLeft(targetDate) {
  const now = new Date(); 
  const endDate = new Date(targetDate);
  const differenceInMilliseconds = endDate - now;
  const daysLeft = Math.ceil(differenceInMilliseconds / (1000 * 60 * 60 * 24)); 

  if (daysLeft >= 1) {
    return `Il reste ${daysLeft} jour(s)`;
  } else {
    return "Terminé";
  }
}

const formatTimeToFrench = (startTime, endTime) => {
  const start = startTime.replace(":", "h");
  const end = endTime.replace(":", "h");
  return `De ${start} à ${end}`;
};

export { truncateString, daysLeft, formatTimeToFrench };
