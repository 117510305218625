import { PhoneModalContextProvider } from "context/PhoneModalContext";
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.css";
import { QRCodeModalContextProvider } from "context/QRModalContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <QRCodeModalContextProvider>
      <PhoneModalContextProvider>
        <App />
      </PhoneModalContextProvider>
    </QRCodeModalContextProvider>
);
