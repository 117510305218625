import Loading from "components/loading/Loading";
import { getActivityById } from "config/activityServices";
import { PhoneModalContext } from "context/PhoneModalContext";
import { daysLeft } from "helpers/Helpers";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

// Import Swiper components
import MobileCard from "components/mobileCard/MobileCard";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import NotFound from "pages/notFound/NotFound";

const ActivityPage = () => {
  const { id } = useParams();
  const { setModalData } = useContext(PhoneModalContext);
  const [loading, setLoading] = useState(false);
  const [activity, setActivity] = useState();

  useEffect(() => {
    const fetchActivities = async () => {
      try {
        setLoading(true);
        const activities = await getActivityById(id);
        if (activities.status === 200) {
          setActivity(activities?.data?.data);
          setModalData({
            cardId: activity?.id,
            color:
              activity?.activitytype?.name === "event" ? "purple" : "orange",
          });
        } else {
          setActivity();
        }
      } catch (e) {
      } finally {
        setLoading(false);
      }
    };

    fetchActivities();
  }, [id]);

  // const textColor =
  //   activity?.activitytype?.id !== 1 ? "text-purple" : "text-orange";
  // const bgColor = activity?.activitytype?.id !== 1 ? "bg-purple" : "bg-orange";

  return (
    <>
      {!loading ? (
        activity?.id ?
        <>
          <MobileCard
            activity={activity}
            cardId={activity?.id}
            title={activity?.title}
            content={activity?.content}
            description={activity?.description}
            picture={activity?.images?.length > 0 && activity?.images[0]}
            countdown={daysLeft(activity?.endDate)}
            isEvent={activity?.activitytype?.id === 2}
          />
        </>
        : <NotFound />
      ) : (
        <Loading />
      )}
    </>
  );
};

export default ActivityPage;
