import { createLead } from "config/leadServices";
import { PhoneModalContext } from "context/PhoneModalContext";
import { useContext, useEffect, useRef, useState } from "react";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-phone-number-input/style.css";

const MobileModal = () => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [error, setError] = useState("");
  const { showMobileModal, modalData, closePhoneModal } = useContext(PhoneModalContext);
  const [loading, setLoading] = useState(false)
  const modalRef = useRef(null);

  useEffect(() => {
    setPhoneNumber("");
    setError("");
  }, [showMobileModal]);

  const validateAndSubmit = async () => {
    setLoading(true)
    if (!phoneNumber) {
      setError("Le numéro de téléphone est requis.");
      setLoading(false)
      return;
    }

    if (!isValidPhoneNumber(phoneNumber)) {
      setError("Veuillez entrer un numéro de téléphone valide.");
      setLoading(false)
      return;
    }

    const data = {
      phone: phoneNumber,
      activityId: modalData?.cardId,
    };
    
    await createLead(data).then(response => {
      if(response?.status === 200){
        toast.success(`Numéro de téléphone soumis. Un message a été envoyé à votre numéro. Veuillez le vérifier !`, { position: 'top-center' });
        closePhoneModal();
      } else {
        toast.warning(response?.data?.error, { position: 'top-center' });
        closePhoneModal();
      }
    }).catch((err) => {
      console.error("Error creating lead ", err)
      toast.success(`Il y a eu un problème, veuillez réessayer.`, { position: 'top-center' });
    })
    setLoading(false)
  };

  const handleOutsideClick = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      closePhoneModal();
    }
  };

  return (
    <>
      <ToastContainer />
      <div
        className={`fixed inset-0 bg-black bg-opacity-50 rounded-lg p-2 flex justify-center items-center z-50 ${
          showMobileModal ? "block" : "hidden"
        }`}
        onClick={handleOutsideClick}
      >
        <div
          className="bg-white max-w-md rounded-lg p-6 shadow-lg"
          ref={modalRef}
        >
          <div className="mb-4 text-lg">
            <label
              className={`block font-medium mb-2 text-${modalData.color}`}
              htmlFor="phone-input"
            >
              Entrez votre numéro de téléphone:
            </label>
            <PhoneInput
              id="phone-input"
              defaultCountry="CH"
              value={phoneNumber}
              onChange={(value) => {
                setPhoneNumber(value);
                setError("");
              }}
              className="w-full p-3 border rounded-lg text-lg"
            />
            {error && <p className="text-red-500 text-sm mt-2">{error}</p>}
          </div>

          <button
            disabled={loading}
            className={`w-full bg-${modalData.color} text-white border py-2 rounded-lg`}
            onClick={validateAndSubmit}
          >
            {loading ? 'Soumettre...' : 'Soumettre'}
          </button>
        </div>
      </div>
    </>
  );
};

export default MobileModal;
