import { FlowIcon } from "assets/icons/FlowIcon";
import GroupsIcon from "assets/icons/GroupsIcon";
import QRIcon from "assets/icons/QRIcon";
import { PhoneModalContext } from "context/PhoneModalContext";
import React, { useContext } from "react";

const BottomBar = ({ isDisabled, type }) => {
  const { openPhoneModal, modalData } = useContext(PhoneModalContext);

  const buttons = [
    {
      title: "Flow",
      icon: <FlowIcon fill={type === 'event' ? '#390977': 'rgba(245, 88, 0, 1)'} width={30} height={30} />,
      onClick: () => openPhoneModal(modalData.cardId, modalData.color),
    },
    {
      title: "Scan",
      icon: <QRIcon fill={type === 'event' ? '#390977' : 'rgba(245, 88, 0, 1)'}  width={30} height={23} />,
      onClick: () => openPhoneModal(modalData.cardId, modalData.color),
    },
    {
      title: "Community",
      icon: <GroupsIcon fill={type === 'event' ? '#390977' : 'rgba(245, 88, 0, 1)'}  width={30} height={23} />,
      onClick: () => openPhoneModal(modalData.cardId, modalData.color),
    },
  ];

  // console.log(type)

  return (
    <div className="w-full flex justify-center px-4 py-2">
      <div className={`flex flex-row rounded-lg items-center ${type === 'event' ? 'text-purple' : 'text-orange'} font-semibold shadow-lg max-w-md bg-white justify-between w-full`}>
        {buttons.map((button, index) => {
          return (
            <button
              className={`p-4 flex-1 text-center justify-center flex ${index !== buttons.length - 1 ? "border-r" : ""}`}
              key={index}
              onClick={button.onClick}
              disabled={isDisabled}
            >
              {button.icon}
            </button>
          );
        })}
      </div>
    </div>
  );
};

export default BottomBar;
