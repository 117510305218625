import MobileModal from "components/mobileModal/MobileModal";
import QRCodeModal from "components/qrCodeModal/QRModal";
import PublicLayout from "layout/publicLayout/PublicLayout";
import ActivityPage from "pages/activityPage/ActivityPage";
import HomePage from "pages/homePage/HomePage";
import MerchantPage from "pages/merchantPage/MerchantPage";
import NotFound from "pages/notFound/NotFound";
import { useEffect } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

function App() {

  // const checkIfAppIsInstalled = () => {
  //   const appScheme = 'com.partof://'; // Custom URL scheme for your app
  //   const storeUrlIOS = 'https://apps.apple.com/us/app/your-app-id'; // iOS App Store URL
  //   const storeUrlAndroid = 'https://play.google.com/store/apps/details?id=com.partof'; // Android Play Store URL
  
  //   const isAndroid = /android/i.test(navigator.userAgent);
  //   const isIOS = /iphone|ipod|ipad/i.test(navigator.userAgent);
  
  //   const startTime = new Date().getTime();
  //   const timeout = 1500; // Time to wait before redirecting
  
  //   if (isAndroid) {
  //     // Try opening the Android app via an intent URL
  //     window.location = `intent://#Intent;package=com.partof;end`;
  
  //     setTimeout(() => {
  //       const endTime = new Date().getTime();
  //       if (endTime - startTime < timeout + 100) {
  //         // If the app did not open, redirect to the Play Store
  //         window.location.href = storeUrlAndroid;
  //       }
  //     }, timeout);
  //   } else if (isIOS) {
  //     // Try opening the app using a custom URL scheme for iOS
  //     window.location = appScheme;
  
  //     setTimeout(() => {
  //       const endTime = new Date().getTime();
  //       if (endTime - startTime < timeout + 100) {
  //         // If the app did not open, redirect to the App Store
  //         window.location.href = storeUrlIOS;
  //       }
  //     }, timeout);
  //   }
  // };

  // useEffect(() => {
  //   checkIfAppIsInstalled()
  // },[])
  

  const router = createBrowserRouter([
    {
      path: "/",
      element: <PublicLayout />,
      errorElement: <NotFound parent={true} />,
      children: [
        {
          path: "",
          element: <HomePage />,
        },
        {
          path: "merchant/:id",
          element: <MerchantPage />,
          handle: { layoutProp: true },
        },
        {
          path: "activity/:id",
          element: <ActivityPage />
        },
      ],
    },
  ]);

  return (
    <>
      <RouterProvider router={router} />
      <MobileModal />
      <QRCodeModal />
    </>
  );
}

export default App;
